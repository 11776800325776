@import './mixins/vertical-align';
@import './base';

.date-input-container {
  position: relative;
}

.date-input-container__input {
  padding-right: 35px !important;
}

.date-input-container__input:not(:disabled) {
  background-color: var(--primary-color) !important;
}

.card .date-input-container__input:not(:disabled),
.dropdown .date-input-container__input:not(:disabled) {
  background-color: var(--input-color) !important;
}

.date-input-container__icon {
  @include vertical-align();

  right: .75rem;
  cursor: pointer;
}

.react-datepicker__close-icon.react-datepicker__close-icon {
  @include vertical-align();

  right: 0;
}

.react-datepicker__close-icon.react-datepicker__close-icon:after {
  right: .75rem;
  line-height: 11px;
  background-color: #333333;
  font-size: 14px;
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: $mainColor;

  &:hover {
    background-color: darken($mainColor, 12%);
  }
}

.react-datepicker.react-datepicker {
  background-color: var(--primary-color);
  color: var(--text-color);
  border-color: var(--border-color);
}

.react-datepicker__header.react-datepicker__header {
  background-color: var(--secondary-color);
  border-color: var(--border-color);
}

.react-datepicker__current-month.react-datepicker__current-month,
.react-datepicker-time__header.react-datepicker-time__header,
.react-datepicker-year-header.react-datepicker-year-header,
.react-datepicker__day-name.react-datepicker__day-name,
.react-datepicker__day:not(:hover).react-datepicker__day:not(:hover),
.react-datepicker__time-name.react-datepicker__time-name {
  color: inherit;
}

.react-datepicker__day--disabled.react-datepicker__day--disabled {
  cursor: default;
  color: var(--border-color) !important;
}

.react-datepicker__day--keyboard-selected.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected.react-datepicker__year-text--keyboard-selected {
  background-color: var(--brand-color) !important;
  color: white !important;
}

.react-datepicker-popper.react-datepicker-popper {
  z-index: 2;

  &[data-placement^='top'] .react-datepicker__triangle.react-datepicker__triangle {
    border-top-color: var(--primary-color);
    border-bottom-color: var(--border-color);

    &::before {
      border-top-color: var(--border-color);
    }
  }

  &[data-placement^='bottom'] .react-datepicker__triangle.react-datepicker__triangle {
    border-top-color: var(--border-color);
    border-bottom-color: var(--secondary-color);

    &::before {
      border-bottom-color: var(--border-color);
    }
  }
}
