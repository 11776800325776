@import '../utils/base';

.short-url-form .card-body > .form-group:last-child,
.short-url-form p:last-child {
  margin-bottom: 0;
}

.short-url-form .card {
  height: 100%;
}
