@import '../utils/base';

.react-tags {
  position: relative;
  padding: 5px 0 0 6px;
  border-radius: .3rem;
  background-color: var(--input-color);
  border: 1px solid var(--input-border-color);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  box-shadow: 0 0 0 .2rem rgb(70 150 229 / 25%);
}

.react-tags__tag {
  font-size: 100%;
}

.react-tags__selected {
  display: inline;
  vertical-align: 2px;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid var(--input-border-color);
  border-radius: .25rem;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #aaaaaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: var(--input-border-color);
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 6px 2px;
  margin-bottom: 5px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: $smMin) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search-input {
  font-size: 1.25rem;
  line-height: inherit;
  color: var(--input-text-color);
  background-color: var(--input-color);

  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0 0 0 7px;
  padding: 0;
  border: 0;
  outline: none;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
}

@media screen and (min-width: $smMin) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: var(--primary-color);
  border: 1px solid var(--border-color);
  border-radius: .25rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
}

.react-tags__suggestions li {
  padding: 8px 10px;
}

.react-tags__suggestions li:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background-color: var(--active-color);
}

.react-tags__suggestions li.is-active {
  background-color: var(--active-color);
}

.react-tags__suggestions li.is-disabled {
  opacity: .5;
  cursor: auto;
}
