@import '../../utils/base';

.responsive-table__header {
  @media (max-width: $responsiveTableBreakpoint) {
    display: none;
  }
}

.responsive-table__row {
  @media (max-width: $responsiveTableBreakpoint) {
    display: block;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
    position: relative;
  }
}

.responsive-table__cell.responsive-table__cell {
  vertical-align: middle !important;

  @media (max-width: $responsiveTableBreakpoint) {
    display: block;
    width: 100%;
    position: relative;
    padding: .5rem;
    font-size: .9rem;

    &[data-th]:before {
      content: attr(data-th) ': ';
      font-weight: 700;
    }

    &:last-child {
      position: absolute;
      top: 3.5px;
      right: .5rem;
      width: auto;
      padding: 0;
      border: none;
    }
  }
}
