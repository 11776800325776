@import '../../utils/base';

.server-form .form-group:last-child {
  margin-bottom: 0;
}

.server-form__label {
  font-weight: 700;
  cursor: pointer;
}
